<script setup lang="ts">
const { locale } = useI18n();
const { slug } = useSlug();

definePageMeta({
  middleware: "cached-page",
});

const { data: contentfulPage, status } = await useFetch<ContentfulPageResponse>(
  `/api/v2/marketing/page/${slug.value}`,
  {
    query: {
      include: slug.value === "faq" ? "8" : undefined,
      locale,
    },
    onResponseError({ response }) {
      const { status: statusCode, statusText: message } = response;

      throw showError({
        statusCode,
        message,
      });
    },
  },
);

const { heroContent } = useContentfulPage(contentfulPage, {
  setSeoMeta: true,
  pageTypeToTrack: PageTypeTrackingId.contentCanvas,
});
</script>

<template>
  <div v-if="status === 'success'" class="main-app">
    <div
      :class="[
        'hero-wrapper',
        { wrap: contentfulPage?.hero.sys.contentType.sys.id !== 'hero' },
      ]"
    >
      <ContentfulSection v-if="heroContent" :content="heroContent" />
    </div>

    <div class="wrap mt56 mb56">
      <template
        v-for="contentEntry in contentfulPage?.content"
        :key="contentEntry.sys.id"
      >
        <ContentfulSection
          v-if="contentEntry.sys.contentType.sys.id === 'section'"
          :content="contentEntry"
          :content-type="contentEntry.sys.contentType.sys.id"
        />
        <ContentfulMissingComponent v-else />
      </template>
    </div>
  </div>
</template>
